<template>
    <div class="pc-my">
        <div class="my-head">
            <div class="my-head__container">

                <img class="my-avatar" src="../../../assets/logo.png" alt="">
                <p class="name">{{ user.username }}</p>
                <div class="df-center">
                    <p class="tag">ID: {{ user.id }}</p>
                    <p class="tag line">{{ $t('user.invitation_code') }}：{{ user.invitation_code }}</p>
                </div>
                <a class="my-tiktok" href="https://www.tiktok.com/" target="_blank">
                    <img class="my-logo" src="../../../assets/logo-text-white.png" alt="">
                    <img src="../../../assets/icon-right-white.png" class="icon-right-double" alt="">
                </a>
            </div>
            <!-- <img class="my-head__bg" src="../../../assets/user_bg.png" alt=""> -->
        </div>

        <div class="my-balance">
            <div class="text-style">
                <span class="my-balance__text">{{( user.balance || '0.00') }}<br></span> {{ $t('user.balance') }}
            </div>
            <div class="my-recharge">
                <van-button class="btn" to="/my/withdraw_funds">
                    <img class="icon" src="../../../assets/icon-my-tx.png" alt="">
                    {{ $t('user.withdraw_cash_now') }}
                </van-button>
                <van-button class="btn" to="/my/recharge">
                    <img class="icon" src="../../../assets/icon-my-cz.png" alt="">
                    {{ $t('user.go_recharge') }}
                </van-button>
                <div class="tag-pc" @click="jumpPc">
                    <van-icon class="tag-icon" size="20px" name="like" color="#F963B6" />
                    <span>{{ $t('user.endowment') }}</span>
                </div>
            </div>
        </div>

        <div>
            <van-button type="danger" class="btn-logout" plain block color="#FF009E" round :loading="loading" @click="_logout">
                {{ $t('user.logout') }}
            </van-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { greeting, logout } from "@/api";

export default {
    data() {
        return {
            loading: false,
            show: false,
            actions: [
                // {name: 'English', value: 'en_US'},
                { name: 'Tiếng Việt', value: 'vi_VN' },
                { name: 'ไทย', value: 'th_TH', },
                { name: '简体中文', value: 'zh_CN' }],
            selectCell: this.$store.state.cellIndex,
        };
    },
    components: {
    },
    computed: {
        ...mapState(["user", 'lang']),
    },
    methods: {
        ...mapActions(['fetchUser']),
        ...mapMutations(['setUser', 'setLang']),
        onSelect(item) {
            // 可以通过 close-on-click-action 属性开启自动收起
            this.show = false;
            this.$i18n.locale = item.value;
            this.setLang(item.value);
            greeting(item.value).then(() => {
                // this.$toast(res.message);
            });
        },
        _logout() {
            this.loading = true;
            logout().then(() => {
                this.setUser({});
                this.loading = false;
                this.$router.push("/login");
            });
        },
        jump() {
            this.$router.push("/endowment");
        },
        jumpPc() {
            this.$router.push("/my/endowment");
        }
    },
    created() {
        this.fetchUser();
    },
}
</script>

<style lang="less" scoped>
.my {
    min-height: 100vh;
    background: url(../../../assets/my-bg.png) left top no-repeat #fff;
    background-size: 100%;
}
.my-recharge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    background: url(../../../assets/my-btn-bg.png) left top no-repeat;
    background-size: 100%;
    margin-top: -61px;
    position: relative;
    z-index: 10;
    padding: 0 16px 10px 16px;
    .hr {
        width: 0;
        height: 14px;
        border-left: 1px solid #d8def3;
    }
    .icon {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 6px;
    }
    .btn-right {
        padding-right: 11px;
    }
}

.my-balance {
    margin: 22px 27px 0 16px;
    width: 332px;
    height: 132px;
    background: url("../../../assets/money-bg.png") left top no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    font-size: 14px;
    color: #e8e8e8;
    position: relative;
    .my-balance__text {
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
    }
    .tag {
        position: absolute;
        right: -15px;
        top: 21px;
        min-width: 82px;
        height: 36px;
        padding: 0 5px;
        padding-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #fc1157;
        background: url("../../../assets/jx-bg.png") left top no-repeat;
        background-size: 100% 100%;
        text-align: center;
        white-space: nowrap;
        .icon {
            margin-top: 4px;
        }
    }
}

.my-head {
    .title {
        line-height: 35px;
        text-align: center;
        font-size: 18px;
        color: #333333;
    }
    .my-head__container {
        padding-left: 16px;
        display: flex;
        justify-content: space-between;

        .my-head__left {
            display: flex;
            align-items: center;
            .line {
                font-size: 13px;
                color: #666666;
            }
            .line.name {
                font-weight: 600;
                font-size: 16px;
                color: #333333;
            }
            .tag {
                width: fit-content;
                padding: 0 10px;
                margin: 2px 0;
                display: flex;
                justify-content: center;
                align-content: center;
                background: rgba(0, 225, 210, 0.34);
                border-radius: 13px 13px 13px 13px;
                background: rgba(0, 225, 210, 0.34);
                border-radius: 13px 13px 13px 13px;
            }
            .my-avatar {
                width: 62px;
                height: 62px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }

        .my-tiktok {
            width: 95px;
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            padding: 0 6px 0 10px;
            border-radius: 20px 0px 0px 20px;

            .my-logo {
                width: 61.15px;
                height: 18.07px;
                margin-right: 6px;
            }
        }
    }
}
.van-cell__right-icon {
    color: #333333;
}
.left-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}
.my-head__bg {
    width: 100%;
}
.cell-style {
    padding-left: 29px;
    padding-right: 26px;
}

.pc-box {
    display: none;
}
@media only screen and (min-width: 750px) {
    .my-head {
        .my-head__container {
            padding-left: 0;
            display: block;
            .my-avatar {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-right: 0;
                display: block;
                margin: 27px auto;
            }
            .name {
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #333333;
            }
            .df-center {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                .tag {
                    height: 28px;
                    line-height: 28px;
                    padding: 0 10px;
                    background: rgba(0, 225, 210, 0.34);
                    border-radius: 14px;
                    border: 0;
                    color: #3663a7;
                    margin: 10px 5px 16px 5px;
                }
                .tag.line {
                    color: #666666;
                    background: rgba(198, 198, 198, 0.34);
                }
            }
            .my-tiktok {
                width: 235px;
                height: 48px;
                display: flex;
                margin: 0 auto;
                justify-content: center;
                align-items: center;
                background: linear-gradient(270deg, #a44afb 0%, #f85dc6 100%);
                border-radius: 25px 25px 25px 25px;
                position: relative;
                .my-logo {
                    width: 78px;
                    height: 23px;
                    margin-right: 0;
                }
                .icon-right-double {
                    width: 8px;
                    height: 9px;
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    margin-top: -5px;
                }
            }
        }
    }

    .my-balance {
        margin: 23px auto;
        width: 90.4%;
        height: 148px;
        border-radius: 30px;
        background: url("../../../assets/money-bg-pc.png") center center
            no-repeat;
        background-size: cover;
        padding: 0 5.3%;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text-style {
            font-size: 18px;
            color: #e8e8e8;
        }
        .my-balance__text {
            font-size: 1.56vw;
            margin-bottom: 8px;
        }
    }
    .my-recharge {
        height: initial;
        justify-content: initial;
        background: none;
        margin-top: 0;
        position: relative;
        z-index: 10;
        padding: 0;
        .icon {
            width: 1.354vw;
            height: 1.354vw;
            vertical-align: middle;
            margin-right: 0.4167vw;
        }
        .btn-right {
            padding-right: 0;
        }
        .btn {
            min-width: 6.667vw;
            height: 42px;
            border-radius: 10px 10px 10px 10px;
            border: 1px solid #ffffff;
            font-size: 0.83vw;
            color: #ffffff;
            background: transparent;
            padding: 0 1.0417vw;
            margin-right: 1.282vw;
        }
        .tag-pc {
            min-width: 82px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.677vw;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            .tag-icon {
                margin-right: 5px;
            }
        }
    }
    .btn-logout {
        width: 319px;
        height: 48px;
        border-radius: 25px 25px 25px 25px;
        border: 1px solid #ff009e;
        font-size: 16px;
        color: #ff009e;
        margin: 45px auto;
    }
    .my-recharge .btn /deep/ .van-button__content {
        white-space: nowrap;
    }
}
</style>
